/* tslint:disable */
/* eslint-disable */
/**
 * Rick and Morty API
 * API for fetching character information from Rick and Morty series
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Character } from './Character';
import {
    CharacterFromJSON,
    CharacterFromJSONTyped,
    CharacterToJSON,
} from './Character';
import type { FetchAllCharacters200ResponseInfo } from './FetchAllCharacters200ResponseInfo';
import {
    FetchAllCharacters200ResponseInfoFromJSON,
    FetchAllCharacters200ResponseInfoFromJSONTyped,
    FetchAllCharacters200ResponseInfoToJSON,
} from './FetchAllCharacters200ResponseInfo';

/**
 * 
 * @export
 * @interface FetchAllCharacters200Response
 */
export interface FetchAllCharacters200Response {
    /**
     * 
     * @type {FetchAllCharacters200ResponseInfo}
     * @memberof FetchAllCharacters200Response
     */
    info?: FetchAllCharacters200ResponseInfo;
    /**
     * 
     * @type {Array<Character>}
     * @memberof FetchAllCharacters200Response
     */
    results?: Array<Character>;
}

/**
 * Check if a given object implements the FetchAllCharacters200Response interface.
 */
export function instanceOfFetchAllCharacters200Response(value: object): boolean {
    return true;
}

export function FetchAllCharacters200ResponseFromJSON(json: any): FetchAllCharacters200Response {
    return FetchAllCharacters200ResponseFromJSONTyped(json, false);
}

export function FetchAllCharacters200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchAllCharacters200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'info': json['info'] == null ? undefined : FetchAllCharacters200ResponseInfoFromJSON(json['info']),
        'results': json['results'] == null ? undefined : ((json['results'] as Array<any>).map(CharacterFromJSON)),
    };
}

export function FetchAllCharacters200ResponseToJSON(value?: FetchAllCharacters200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'info': FetchAllCharacters200ResponseInfoToJSON(value['info']),
        'results': value['results'] == null ? undefined : ((value['results'] as Array<any>).map(CharacterToJSON)),
    };
}

