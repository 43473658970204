/* tslint:disable */
/* eslint-disable */
/**
 * Rick and Morty API
 * API for fetching character information from Rick and Morty series
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CharacterLocation } from './CharacterLocation';
import {
    CharacterLocationFromJSON,
    CharacterLocationFromJSONTyped,
    CharacterLocationToJSON,
} from './CharacterLocation';
import type { CharacterOrigin } from './CharacterOrigin';
import {
    CharacterOriginFromJSON,
    CharacterOriginFromJSONTyped,
    CharacterOriginToJSON,
} from './CharacterOrigin';

/**
 * 
 * @export
 * @interface Character
 */
export interface Character {
    /**
     * 
     * @type {number}
     * @memberof Character
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Character
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Character
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Character
     */
    species?: string;
    /**
     * 
     * @type {string}
     * @memberof Character
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Character
     */
    gender?: string;
    /**
     * 
     * @type {CharacterOrigin}
     * @memberof Character
     */
    origin?: CharacterOrigin;
    /**
     * 
     * @type {CharacterLocation}
     * @memberof Character
     */
    location?: CharacterLocation;
    /**
     * 
     * @type {string}
     * @memberof Character
     */
    image?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Character
     */
    episode?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Character
     */
    url?: string;
    /**
     * 
     * @type {Date}
     * @memberof Character
     */
    created?: Date;
}

/**
 * Check if a given object implements the Character interface.
 */
export function instanceOfCharacter(value: object): boolean {
    return true;
}

export function CharacterFromJSON(json: any): Character {
    return CharacterFromJSONTyped(json, false);
}

export function CharacterFromJSONTyped(json: any, ignoreDiscriminator: boolean): Character {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'status': json['status'] == null ? undefined : json['status'],
        'species': json['species'] == null ? undefined : json['species'],
        'type': json['type'] == null ? undefined : json['type'],
        'gender': json['gender'] == null ? undefined : json['gender'],
        'origin': json['origin'] == null ? undefined : CharacterOriginFromJSON(json['origin']),
        'location': json['location'] == null ? undefined : CharacterLocationFromJSON(json['location']),
        'image': json['image'] == null ? undefined : json['image'],
        'episode': json['episode'] == null ? undefined : json['episode'],
        'url': json['url'] == null ? undefined : json['url'],
        'created': json['created'] == null ? undefined : (new Date(json['created'])),
    };
}

export function CharacterToJSON(value?: Character | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'status': value['status'],
        'species': value['species'],
        'type': value['type'],
        'gender': value['gender'],
        'origin': CharacterOriginToJSON(value['origin']),
        'location': CharacterLocationToJSON(value['location']),
        'image': value['image'],
        'episode': value['episode'],
        'url': value['url'],
        'created': value['created'] == null ? undefined : ((value['created']).toISOString()),
    };
}

