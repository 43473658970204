/* tslint:disable */
/* eslint-disable */
/**
 * Rick and Morty API
 * API for fetching character information from Rick and Morty series
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Character,
  FetchAllCharacters200Response,
} from '../models/index';
import {
    CharacterFromJSON,
    CharacterToJSON,
    FetchAllCharacters200ResponseFromJSON,
    FetchAllCharacters200ResponseToJSON,
} from '../models/index';

export interface FetchSingleCharacterRequest {
    id: number;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Fetch all characters
     */
    async fetchAllCharactersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FetchAllCharacters200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/character`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FetchAllCharacters200ResponseFromJSON(jsonValue));
    }

    /**
     * Fetch all characters
     */
    async fetchAllCharacters(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FetchAllCharacters200Response> {
        const response = await this.fetchAllCharactersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Fetch a single character by ID
     */
    async fetchSingleCharacterRaw(requestParameters: FetchSingleCharacterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Character>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchSingleCharacter().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/character/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CharacterFromJSON(jsonValue));
    }

    /**
     * Fetch a single character by ID
     */
    async fetchSingleCharacter(requestParameters: FetchSingleCharacterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Character> {
        const response = await this.fetchSingleCharacterRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
