import React from 'react';
import { useConfig } from '../context/ConfigContext';

const DevToolbar = () => {
  const { appVersion, environment } = useConfig();

  if (environment === 'PROD') {
    return null;
  }

  return (
    <div className="absolute right-10 bottom-10 bg-gray-100 rounded-md p-2 text-gray-400 shadow-md">
      ENV: {environment} - VER: {appVersion}
    </div>
  );
};

export default DevToolbar;
